import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import { Button } from "../button"
import Faq from 'react-faq-component'

const ServiceContentTemplate = (props) => {
    let servicesData = props.data
    let projectsData = props.projectData
    let servicesArray = []
    let descriptionsArray = []

    for (let i in servicesData.servicesList.services) {
        servicesArray.push([servicesData.servicesList.services[i]])
    }

    for (let i in servicesData.servicesList.descriptions) {
        descriptionsArray.push([servicesData.servicesList.descriptions[i]])
    }

    // let servicesList = servicesArray.map((service, index) => {
    //     return { service: service, description: descriptionsArray[index] };
    // })

    const data = {
        rows: [],
        styles: {
            // bgColor: 'white',
            // titleTextColor: 'blue',
            // titleTextSize: '48px',
            // rowTitleColor: 'blue',
            // rowTitleTextSize: 'medium',
            // rowContentColor: 'grey',
            rowContentTextSize: '16px',
            // rowContentPaddingTop: '10px',
            rowContentPaddingBottom: '10px',
            // rowContentPaddingLeft: '50px',
            // rowContentPaddingRight: '150px',
            // arrowColor: "red",
            //transitionDuration: "1s",
            // timingFunc: "ease"
        }
    }
    for (let i = 0; i < servicesData.servicesList.services.length; i++) {
        data.rows.push({
            title:
                <div className="flex items-center gap-x-2 font-semibold">
                    <span className="border bg-darkblue w-7 h-7 flex items-center justify-center text-white text-sm">{i + 1}</span>
                    {servicesData.servicesList.services[i]}
                </div>,
            content: servicesData.servicesList.descriptions[i],
        })
    }

    return (
        <section className="pt-10 pb-9">
            <div className="section-padding">
                <div className="container mx-auto">
                    <div className='flex flex-wrap'>
                        <div className="md:w-full pr-4 pl-4">
                            <h5>{servicesData.serviceName} Services</h5>
                            <p>
                                {servicesData.paragraph1.paragraph1}
                            </p>
                            <p className="mb-30">
                                {servicesData.paragraph2.paragraph2}
                            </p>
                            {/* <div className="px-3 max-w-[21rem] sm:max-w-[37rem] md:max-w-[47rem] lg:max-w-[63rem] mx-auto">
                                <ServicesImageGallery imagesGraph={servicesData.images} title={servicesData.images.title} />
                            </div> */}
                            <div className="mx-auto grid gap-x-8 lg:grid-cols-2 xl:grid-cols-3 gap-y-8">
                                {projectsData && projectsData.filter(({ node: projects }) => projects.serviceName.includes(servicesData.serviceName)).slice(0, 3).map(({ node: project }) => {
                                    const image = getImage(project.mainImage)
                                    let cardTitle = project.serviceName + " Project In " + project.city
                                    return (
                                        <div className="flex flex-col mx-auto border shadow-xl mb-3 xl:mb-12 bg-white" key={project.slug}>
                                            <article>
                                                <div className="max-w-md mx-auto pb-2">
                                                    <Link to={project.slug} title={cardTitle} alt={cardTitle}>
                                                        <GatsbyImage className="h-56 z-0 hover:opacity-80" src="" image={image} title={project.mainImage.title} alt={project.mainImage.description} />
                                                    </Link>
                                                    <div className="px-5 py-5">
                                                        <p className="font-medium text-gray-600 uppercase text-sm">{project.serviceName}</p>
                                                        <Link to={project.slug} title={cardTitle} alt={cardTitle}>
                                                            <h3 className="md:h-12 lg:h-14 font-bold text-lg hover:text-lightblue">{project.serviceName} Project in {project.city}</h3>
                                                        </Link>
                                                        <p className="text-base">View examples of the {project.serviceName} services that we offer!</p>
                                                        <Link to={project.slug} className="link-btn" tabIndex="0" title={cardTitle} alt={cardTitle}>View Project</Link>
                                                    </div>
                                                </div>
                                            </article>
                                        </div>
                                    )
                                })}
                            </div>

                            <div className="mx-auto flex justify-center mt-5 mb-1 lg:mb-2 xl:mt-0">
                                <Button buttonText='View All Projects' buttonPage='/projects' />
                            </div>

                            <h5 className="pt-5">Our {servicesData.serviceName} Services Inlcude:</h5>
                            {/* <ol className="number mb-30">
                                {servicesList.map(function (element) {
                                    return ( 
                                        <li className="font-bold">{element.service}
                                            <br />
                                            <p className="pt-2">{element.description}</p>
                                        </li>
                                        
                                    )
                                })}
                            </ol> */}
                            <div className="mb-8">
                                <Faq data={data} styles={data.styles} />
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-wrap">
                        <div className="md:w-full pr-4 pl-4">
                            {/* <p className="mb-30">Architecture lonon lorem ac erat suscipit bibendum. Nulla facilisi. Sedeuter nunc volutpat, mollis sapien vel, conseyer turpeutionyer masin libero sempe. Fusceler mollis augue sit amet hendrerit vestibulum. Duisteyerionyer venenatis lacus. Gravida eros ut turpis interdum ornare. Interdum et malesu they adamale fames ac anteipsu pimsine faucibus. Curabitur arcu site feugiat in torto.</p> */}
                            <h6>Why choose us for {servicesData.serviceName}?</h6>
                            <div className="" dangerouslySetInnerHTML={{
                                __html: servicesData.finalParagraph.childMarkdownRemark.html,
                            }} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default ServiceContentTemplate