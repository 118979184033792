import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"
import ServiceContentTemplate from "../components/Services-Page/main-content"
import { ServicesSectionServicePg } from "../components/Services-Page/services-section"
import { HeaderBannerServicesPg } from "../components/Services-Page/header-banner-services"

const ServicePageTemplate = (props) => {
    return (
        <Layout>
            <Seo
                title={"Kaylar Construction: Premier " + props.data.contentfulService.serviceName + " Services for Stunning Home Transformations"}
                description={"Explore our comprehensive general contracting services for " + props.data.contentfulService.serviceName + ". Trust our expertise to bring your home improvement dreams to life with quality craftsmanship and personalized solutions."}
                location={props.location.pathname}
                keywords={[props.data.contentfulService.serviceName]}
            />
            <HeaderBannerServicesPg data={props.data.contentfulService} />
            <ServiceContentTemplate data={props.data.contentfulService} projectData={props.data.allContentfulProjects.edges} />
            <ServicesSectionServicePg data={props.data.contentfulService} />
        </Layout>
    )
}
export default ServicePageTemplate
export const pageQuery = graphql`
query ContentfulServiceBySlug($slug: String !) {
    contentfulService( slug: {eq: $slug }) {
        serviceName
        slug
        headerText {
            childMarkdownRemark {
                html
            }
        }
        paragraph1 {
            paragraph1
        }
        paragraph2 {
            paragraph2
        }
        servicesList {
            services
            descriptions
        }
        mainImage {
            id
            description
            gatsbyImageData (
                placeholder: NONE
                quality: 100
            )
            url
            title
        }
        finalParagraph {
          childMarkdownRemark {
            html
          }
        }
    },
    allContentfulProjects(sort: { fields: date, order: DESC }) {
        edges{
            node{
                slug
                mainImage {
                    title
                    description
                    url
                    gatsbyImageData (
                      placeholder: NONE
                    )
                  }
                city
                serviceName
                date               
            }
        }
    }
}
`