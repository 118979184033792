import React, { Component } from "react"
import { Button } from "../button"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { GatsbyImage } from "gatsby-plugin-image"

export class HeaderBannerServicesPg extends Component {
    render() {
        let servicesData = this.props.data
        let anchoredLink = servicesData.slug + '#otherservices'
        let isKitchenService = false
        let isBathroomService = false
        let isSidingService = false
        let isWindowsService = false
        let isDecksPatioService = false
        let isInteriorDesignService = false

        if (this.props.data.serviceName === 'Kitchen Remodeling') {
            isKitchenService = true
        }
        else if (this.props.data.serviceName === 'Bathroom Remodeling') {
            isBathroomService = true
        }
        else if (this.props.data.serviceName === 'Siding') {
            isSidingService = true
        }
        else if (this.props.data.serviceName === 'Windows & Doors') {
            isWindowsService = true
        }
        else if (this.props.data.serviceName === 'Decks & Patios') {
            isDecksPatioService = true
        }
        else if (this.props.data.serviceName === 'Interior Design') {
            isInteriorDesignService = true
        }

        return (
            <header className="header">
                <div className="text-left">
                    <div className="caption">
                        <div className="container mx-auto">
                            <div className="relative z-10">
                                <div className={`absolute text-white md:w-3/4 pr-4 pl-4 ${isKitchenService ? 'top-[10.5rem] md:top-[16rem] lg:top-[17.5rem] xl:top-[17rem]' : isBathroomService ? 'top-[10.5rem] md:top-[14.5rem] lg:top-[17.5rem] xl:top-[17rem]' : isSidingService ? 'top-[10.5rem] md:top-[16rem] lg:top-[17.5rem] xl:top-[17rem]' : isWindowsService ? 'top-[10.5rem] md:top-[17.5rem] lg:top-[18.5rem] xl:top-[18rem]' : isDecksPatioService ? 'top-[10.5rem] md:top-[14.5rem] lg:top-[16.5rem] xl:top-[16rem]' : isInteriorDesignService ? 'top-[10.5rem] md:top-[15rem] lg:top-[16.5rem] xl:top-[17rem]' : 'top-[12.5rem] md:top-[12.7rem] lg:top-56'}`}>
                                    <h4>Our Services</h4>
                                    <h1>{servicesData.serviceName}</h1>
                                    <div className="" dangerouslySetInnerHTML={{
                                        __html: servicesData.headerText.childMarkdownRemark.html,
                                    }}>
                                    </div>
                                    <div className="flex space-x-5">
                                        <AnchorLink to={anchoredLink} title='View Our Other Services' alt='View Our Other Services'>
                                            <button className='relative h-10 py-2 px-5 transition-colors rounded-lg border focus:shadow-outline hover:shadow-lg font-semibold transition duration-300 ease-in-out text-sm border-darkblue bg-darkblue text-white hover:bg-lightblue hover:text-white'>
                                                <span>Other Services</span>
                                            </button>
                                        </AnchorLink>
                                        <Button buttonPage="/contact" buttonText="Contact Us" lightBlue={true} />
                                    </div>
                                    <div className="text-white pt-6">
                                        <a href="tel:+19162450073" className="hover:text-lightblue transition duration-300 ease-in-out" title='Call Kaylar Construction' alt='Call Kaylar Construction' ><span className="fa fa-phone"></span>(916) 245-0073</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <GatsbyImage image={servicesData.mainImage.gatsbyImageData} className={`object-cover w-full md:h-[800px] lg:h-[800px] xl:h-[770px] ${isKitchenService ? 'h-[740px] sm:h-[730px]' : isBathroomService ? 'h-[780px] sm:h-[730px]' : isSidingService ? 'h-[720px] sm:h-[730px]' : isWindowsService ? 'h-[650px] sm:h-[730px]' : isDecksPatioService ? 'h-[830px] sm:h-[730px]' : isInteriorDesignService ? 'h-[750px] sm:h-[730px]' : 'h-[820px] sm:h-[730px]'}`} placeholder="none" title={servicesData.mainImage.title + ' - Kaylar Construction'} alt={servicesData.mainImage.title + ' - Kaylar Construction'} />
            </header>
        )
    }
}